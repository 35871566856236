import React from "react";
import { Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const DespreNoi = () => {
  return (
    <div className="container mt-5">
      <h1 className="main-heading">Despre Noi</h1>

      <p>
        Bine ați venit la Calculatorul de Impozite! Suntem aici pentru a vă
        ajuta să estimați impozitele și să generați contracte de
        vânzare-cumpărare pentru vehiculele dumneavoastră.
      </p>

      <div className="d-flex justify-content-around mt-5">
        <Card style={{ width: "18rem" }}>
          <Card.Body>
            <Card.Title>Calculator Impozite</Card.Title>
            <Card.Text>
              Accesează calculatorul nostru pentru a estima impozitele tale.
            </Card.Text>
            <Link to="/impozit-calculator" className="btn btn-primary">
              Accesează
            </Link>
          </Card.Body>
        </Card>

        <Card style={{ width: "18rem" }}>
          <Card.Body>
            <Card.Title>Contract Vanzare-Cumparare</Card.Title>
            <Card.Text>
              Generează automat un contract pentru instrainare/dobandire auto.
            </Card.Text>
            <Link to="/contract-vanzare-cumparare" className="btn btn-primary">
              Accesează
            </Link>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default DespreNoi;
