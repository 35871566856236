import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";

const Contact = () => {
  const [formData, setFormData] = useState({
    nume: "",
    email: "",
    telefon: "",
    tipMesaj: "tehnic",
    subiect: "",
    mesaj: "",
  });
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Setează starea pentru a arăta că formularul a fost trimis
    setFormSubmitted(true);
  };

  return (
    <div className="container mt-5">
      <h1>Contact</h1>

      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formNume">
          <Form.Label>Nume</Form.Label>
          <Form.Control
            type="text"
            placeholder="Introdu numele tău"
            name="nume"
            value={formData.nume}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="formEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Introdu adresa ta de email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="formTelefon">
          <Form.Label>Telefon</Form.Label>
          <Form.Control
            type="tel"
            placeholder="Introdu numărul de telefon"
            name="telefon"
            value={formData.telefon}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="formTipMesaj">
          <Form.Label>Tipul Mesajului</Form.Label>
          <Form.Control
            as="select"
            name="tipMesaj"
            value={formData.tipMesaj}
            onChange={handleChange}
          >
            <option value="tehnic">Tehnic</option>
            <option value="comercial">Comercial</option>
            <option value="parteneriate">Parteneriate</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="formSubiect">
          <Form.Label>Subiect</Form.Label>
          <Form.Control
            type="text"
            placeholder="Introdu subiectul mesajului"
            name="subiect"
            value={formData.subiect}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="formMesaj">
          <Form.Label>Mesaj</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Scrie mesajul tău aici"
            name="mesaj"
            value={formData.mesaj}
            onChange={handleChange}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Trimite Mesajul
        </Button>
      </Form>

      {/* Afișează datele introduse după submit */}
      {formSubmitted && (
        <div className="mt-4">
          <h2>Date introduse:</h2>
          <p>
            <strong>Nume:</strong> {formData.nume}
          </p>
          <p>
            <strong>Email:</strong> {formData.email}
          </p>
          <p>
            <strong>Telefon:</strong> {formData.telefon}
          </p>
          <p>
            <strong>Tip Mesaj:</strong> {formData.tipMesaj}
          </p>
          <p>
            <strong>Subiect:</strong> {formData.subiect}
          </p>
          <p>
            <strong>Mesaj:</strong> {formData.mesaj}
          </p>
        </div>
      )}
    </div>
  );
};

export default Contact;
