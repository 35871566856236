// src/App.js
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Marketplace from "./Marketplace";
import Login from "./Login";
import Navigation from "./Navigation";
import Home from "./Home";
import Calculator from "./Calculator";
import ContractVanzareCumparare from "./ContractVanzareCumparare";
import Contact from "./Contact";
import DespreNoi from "./DespreNoi";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./Footer";

const App = () => {
  // Modificări pentru a exclude verificările de autentificare temporar
  const isLoggedIn = true; // Setează manual starea de autentificare
  const handleLogin = () => {}; // Exclude temporar logica de autentificare
  const handleLogout = () => {}; // Exclude temporar logica de delogare

  return (
    <Router>
      <div>
        <Navigation isLoggedIn={isLoggedIn} onLogout={handleLogout} />

        <hr />
        <Routes>
          <Route path="/impozit-calculator" element={<Calculator />} />
          <Route
            path="/contract-vanzare-cumparare"
            element={<ContractVanzareCumparare />}
          />
          <Route path="/despre-noi" element={<DespreNoi />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/marketplace" element={<Marketplace />} />

          <Route path="/" element={<Home />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
