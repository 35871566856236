// src/Navigation.js
import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Navigation.css";
import { Link } from "react-router-dom"; // Adăugați această linie

const Navigation = () => {
  return (
    <Navbar bg="blue" expand="md">
      <Navbar.Brand as={Link} to="/">
        Home
      </Navbar.Brand>
      <Navbar.Toggle
        aria-controls="basic-navbar-nav"
        className="navbar-toggler-color"
      />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link as={Link} to="/impozit-calculator">
            Calculator
          </Nav.Link>
          <Nav.Link as={Link} to="/contract-vanzare-cumparare">
            Contract Vanzare-Cumparare
          </Nav.Link>
          <Nav.Link as={Link} to="/Marketplace">
            Marketplace
          </Nav.Link>
          <Nav.Link as={Link} to="/despre-noi">
            Despre Noi
          </Nav.Link>
          <Nav.Link as={Link} to="/contact">
            Contact
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Navigation;
