import React, { useState } from "react";
import jsPDF from "jspdf";
import "bootstrap/dist/css/bootstrap.min.css";
import "./ContractVanzare.css";
const ContractVanzareCumparare = () => {
  const [formData, setFormData] = useState({
    instrainator: {
      nume: "",
      domiciliuJudet: "",
      codPostal: "",
      municipiuOras: "",
      strada: "",
      email: "",
      calitate: "",
    },
    cumparator: {
      nume: "",
      domiciliuJudet: "",
      codPostal: "",
      municipiuOras: "",
      strada: "",
      email: "",
      calitate: "",
    },

    // ...
  });

  const handleDownload = () => {
    // Creează un document jsPDF
    const doc = new jsPDF();
    doc.setFontSize(10); // Setează dimensiunea fontului la 10

    // Înălțimea și lățimea paginii
    const pageHeight = doc.internal.pageSize.height;
    const pageWidth = doc.internal.pageSize.width;

    // Definirea înălțimilor pentru secțiunile superioară și inferioară
    const topSectionHeight = pageHeight * 0.15;
    const bottomSectionHeight = pageHeight * 0.15;

    // Secțiunea superioară (momentan goală)
    doc.setFillColor(255, 255, 255);
    doc.rect(0, 0, pageWidth, topSectionHeight, "F");

    // Secțiunea inferioară (momentan goală)
    doc.rect(
      0,
      pageHeight - bottomSectionHeight,
      pageWidth,
      bottomSectionHeight,
      "F"
    );

    // Secțiunea de mijloc
    const middleSectionHeight =
      pageHeight - topSectionHeight - bottomSectionHeight;
    const middleSectionTop = topSectionHeight;
    // Adaugă text în colțul din stânga sus
    doc.text("Denumirea organului fiscal: ........", 10, 10);
    doc.text("REMTII: ........", 15, 15);

    // Adaugă text în colțul din dreapta sus
    doc.text("Vinzatorul nu are datorii.", 150, 10);
    doc.text("........", 150, 20);

    // Adăugare conținut în secțiunea de mijloc

    // Adaugă conținutul pentru prima secțiune
    doc.text("1. Persoana care instraineaza:", 20, 20);
    doc.text(`Nume: ${formData.instrainator.nume}`, 30, 30);
    doc.text(
      `Domiciliu Judet: ${formData.instrainator.domiciliuJudet}`,
      30,
      40
    );

    // Adaugă conținutul pentru a doua secțiune
    doc.text("2. Persoana care dobandeste:", 20, 60);
    doc.text(`Nume: ${formData.cumparator.nume}`, 30, 70);
    doc.text(`Domiciliu Judet: ${formData.cumparator.domiciliuJudet}`, 30, 80);

    // Adaugă conținutul pentru a treia secțiune
    doc.text("3. Obiectul contractului:", 20, middleSectionTop + 60);
    doc.text(`Marca: ${formData.cumparator.marca}`, 30, middleSectionTop + 70);
    doc.text(`Tip: ${formData.cumparator.tip}`, 30, middleSectionTop + 80);
    doc.text(`Sasiu: ${formData.cumparator.sasiu}`, 30, middleSectionTop + 90);
    doc.text(`An: ${formData.cumparator.an}`, 30, middleSectionTop + 100);

    // Adaugă conținutul pentru a patra secțiune
    doc.text("4. Pretul:", 20, middleSectionTop + 130);
    doc.text(
      `Pretul in litere: ${formData.cumparator.litere}`,
      30,
      middleSectionTop + 140
    );
    doc.text(
      `Pretul in cifre: ${formData.cumparator.cifre}`,
      30,
      middleSectionTop + 150
    );
    // Descarcă documentul
    doc.save("contract_vanzare_cumparare.pdf");
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <h2>Contract Instrăinare Dobândire</h2>
          <form>
            <h3>1. Persoana care instraineaza:</h3>
            <label className="mb-2">
              Nume:
              <input
                type="text"
                className="form-control"
                value={formData.instrainator.nume}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    instrainator: {
                      ...formData.instrainator,
                      nume: e.target.value,
                    },
                  })
                }
              />
            </label>
            <label className="mb-2">
              Domiciliu Judet:
              <input
                type="text"
                className="form-control"
                value={formData.instrainator.domiciliuJudet}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    instrainator: {
                      ...formData.instrainator,
                      domiciliuJudet: e.target.value,
                    },
                  })
                }
              />
            </label>
            {/* Adăugați și celelalte input-uri pentru această secțiune */}
            <h3>2. Persoana care dobandeste:</h3>
            <label className="mb-2">
              Nume:
              <input
                type="text"
                className="form-control"
                value={formData.cumparator.nume}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    cumparator: {
                      ...formData.cumparator,
                      nume: e.target.value,
                    },
                  })
                }
              />
            </label>
            <label className="mb-2">
              Domiciliu Judet:
              <input
                type="text"
                className="form-control"
                value={formData.cumparator.domiciliuJudet}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    cumparator: {
                      ...formData.cumparator,
                      domiciliuJudet: e.target.value,
                    },
                  })
                }
              />
            </label>

            <h3>3. Obiectul contractului:</h3>
            <label className="mb-2">
              Marca:
              <input
                type="text"
                className="form-control"
                value={formData.cumparator.marca}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    cumparator: {
                      ...formData.cumparator,
                      marca: e.target.value,
                    },
                  })
                }
              />
            </label>
            <label className="mb-2">
              Tip:
              <input
                type="text"
                className="form-control"
                value={formData.cumparator.tip}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    cumparator: {
                      ...formData.cumparator,
                      tip: e.target.value,
                    },
                  })
                }
              />
            </label>

            <label className="mb-2">
              Sasiu:
              <input
                type="text"
                className="form-control"
                value={formData.cumparator.sasiu}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    cumparator: {
                      ...formData.cumparator,
                      sasiu: e.target.value,
                    },
                  })
                }
              />
            </label>

            <label className="mb-2">
              Anul fabricatiei:
              <input
                type="text"
                className="form-control"
                value={formData.cumparator.an}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    cumparator: {
                      ...formData.cumparator,
                      an: e.target.value,
                    },
                  })
                }
              />
            </label>

            <h3>4. Pretul:</h3>
            <label className="mb-2">
              Pretul in litere:
              <input
                type="text"
                className="form-control"
                value={formData.cumparator.litere}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    cumparator: {
                      ...formData.cumparator,
                      litere: e.target.value,
                    },
                  })
                }
              />
            </label>
            <label className="mb-2">
              Pretul in cifre:
              <input
                type="text"
                className="form-control"
                value={formData.cumparator.cifre}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    cumparator: {
                      ...formData.cumparator,
                      cifre: e.target.value,
                    },
                  })
                }
              />
            </label>

            <button type="button" onClick={handleDownload}>
              Descarcă Contract
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
export default ContractVanzareCumparare;
