// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCjlsxjQzZEsCe8lN7ppr3NJ-GcNE8jJKo",
  authDomain: "examen-cristi.firebaseapp.com",
  projectId: "examen-cristi",
  storageBucket: "examen-cristi.appspot.com",
  messagingSenderId: "506010494248",
  appId: "1:506010494248:web:133e4882ac0a6cb40c1c1d",
  measurementId: "G-EFSBR8GZL1",
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const auth = getAuth(app);

export { firestore, auth };
