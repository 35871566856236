// Marketplace.js
import React, { useState, useEffect } from "react";
import { firestore } from "./firebase";
import { collection, addDoc, onSnapshot } from "firebase/firestore";

const Marketplace = () => {
  const [ads, setAds] = useState([]);
  const [newAd, setNewAd] = useState({
    brand: "",
    year: "",
    engineCapacity: "",
    mileage: "",
    price: "",
    contactEmail: "",
  });

  // Ascultă modificările din colecția "ads" și actualizează starea anunțurilor
  useEffect(() => {
    const unsubscribe = onSnapshot(collection(firestore, "ads"), (snapshot) => {
      const newAds = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAds(newAds);
    });

    return () => unsubscribe();
  }, []);

  const handleAddAd = async (event) => {
    event.preventDefault();
    try {
      // Adaugă noul anunț în colecția "ads"
      await addDoc(collection(firestore, "ads"), {
        ...newAd,
        timestamp: new Date(),
      });

      // Resetează starea pentru noul anunț
      setNewAd({
        brand: "",
        year: "",
        engineCapacity: "",
        mileage: "",
        price: "",
        contactEmail: "",
      });
    } catch (error) {
      console.error("Eroare la adăugarea anunțului:", error);
    }
  };

  return (
    <div className="container mt-5">
      <h2>Marketplace</h2>

      <div className="row">
        <div className="col-md-6">
          <h3>Adăugare anunț</h3>
          <form onSubmit={handleAddAd}>
            <div className="mb-3">
              <label>Marca mașinii:</label>
              <input
                type="text"
                value={newAd.brand}
                onChange={(e) => setNewAd({ ...newAd, brand: e.target.value })}
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <label>Anul fabricației:</label>
              <input
                type="text"
                value={newAd.year}
                onChange={(e) => setNewAd({ ...newAd, year: e.target.value })}
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <label>Capacitate cilindrică:</label>
              <input
                type="text"
                value={newAd.engineCapacity}
                onChange={(e) =>
                  setNewAd({ ...newAd, engineCapacity: e.target.value })
                }
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <label>Kilometraj:</label>
              <input
                type="text"
                value={newAd.mileage}
                onChange={(e) =>
                  setNewAd({ ...newAd, mileage: e.target.value })
                }
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <label>Preț:</label>
              <input
                type="text"
                value={newAd.price}
                onChange={(e) => setNewAd({ ...newAd, price: e.target.value })}
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <label>Email de contact:</label>
              <input
                type="text"
                value={newAd.contactEmail}
                onChange={(e) =>
                  setNewAd({ ...newAd, contactEmail: e.target.value })
                }
                className="form-control"
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Adaugă anunț
            </button>
          </form>
        </div>

        <div className="col-md-6">
          <h3>Anunțuri</h3>
          <div className="card-container">
            {ads.map((ad) => (
              <div className="card mb-3" key={ad.id}>
                <div className="card-body">
                  <h4 className="card-title">{ad.brand}</h4>
                  <p className="card-text">Anul fabricației: {ad.year}</p>
                  <p className="card-text">
                    Capacitate cilindrică: {ad.engineCapacity}
                  </p>
                  <p className="card-text">Kilometraj: {ad.mileage}</p>
                  <p className="card-text">Preț: {ad.price}</p>
                  <p className="card-text">
                    Email de contact: {ad.contactEmail}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Marketplace;
