// src/Home.js
import React from "react";
import { Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Home.css"; // Importați fișierul CSS

const Home = () => {
  return (
    <Card bg="primary" text="white" className="text-center mx-auto custom-card">
      <Card.Body>
        <Card.Title>Bine ați venit la Calculatorul de Impozite!</Card.Title>
        <Card.Text>
          Calculează rapid și simplu impozitul pentru mijloacele tale de
          transport.
        </Card.Text>
        <Button variant="light" href="/impozit-calculator">
          Calculează acum
        </Button>
      </Card.Body>
    </Card>
  );
};

export default Home;
