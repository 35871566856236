// src/Calculator.js
import React, { useState } from "react";
import { Container, Form, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

function Calculator() {
  const [capacitateCilindrica, setCapacitateCilindrica] = useState("");
  const [esteHibrid, setEsteHibrid] = useState(false);
  const [vehicleType, setVehicleType] = useState("autoturism");
  const [impozit, setImpozit] = useState(null);

  const handleCalculImpozit = () => {
    try {
      // Convertim capacitatea cilindrică la număr întreg
      const capacitateInt = parseInt(capacitateCilindrica, 10);

      // Calculam impozitul fara reducerea pentru hibrid
      let impozitFaraReducere;
      if (vehicleType === "autoturism") {
        impozitFaraReducere =
          capacitateInt <= 1600
            ? 10 * Math.ceil(capacitateInt / 200)
            : capacitateInt <= 2000
            ? 25 * Math.ceil(capacitateInt / 200)
            : capacitateInt <= 2600
            ? 97 * Math.ceil(capacitateInt / 200)
            : capacitateInt <= 3000
            ? 195 * Math.ceil(capacitateInt / 200)
            : 393 * Math.ceil(capacitateInt / 200);
      } else if (vehicleType === "autoutilitara") {
        // Formula de calcul pentru autoutilitare
        impozitFaraReducere = 40 * Math.ceil(capacitateInt / 200);
      } else {
        throw new Error("Tip de vehicul necunoscut");
      }

      const impozitFinal = esteHibrid
        ? impozitFaraReducere * 0.05
        : impozitFaraReducere;

      setImpozit(impozitFinal);
    } catch (error) {
      console.error("Eroare la calculul impozitului:", error);
    }
  };

  return (
    <div className="bg-gradient text-dark min-vh-100 d-flex align-items-center justify-content-center">
      <Container>
        <h2 className="mb-4 text-center">Calculator Impozit</h2>
        <Form>
          <Form.Group controlId="capacitateCilindrica" className="mb-3">
            <Form.Label>Capacitate cilindrică (cm³):</Form.Label>
            <Form.Control
              type="number"
              value={capacitateCilindrica}
              onChange={(e) => setCapacitateCilindrica(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="esteHibrid" className="mb-3">
            <Form.Check
              type="checkbox"
              label="Este hibrid"
              checked={esteHibrid}
              onChange={(e) => setEsteHibrid(e.target.checked)}
            />
          </Form.Group>

          <Form.Group controlId="vehicleType" className="mb-3">
            <Form.Label>Tip vehicul:</Form.Label>
            <Form.Control
              as="select"
              value={vehicleType}
              onChange={(e) => setVehicleType(e.target.value)}
            >
              <option value="autoturism">Autoturism</option>
              <option value="autoutilitara">Autoutilitară</option>
            </Form.Control>
          </Form.Group>

          <Button
            variant="primary"
            onClick={handleCalculImpozit}
            className="w-100"
          >
            Calculează Impozit
          </Button>
        </Form>

        {impozit !== null && (
          <div className="mt-4 text-center">
            <h3>Rezultatul Calculului:</h3>
            <p>Impozitul este: {impozit} lei</p>
          </div>
        )}
      </Container>
    </div>
  );
}

export default Calculator;
